.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background: rgba(49, 49, 49, 0.8); */
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 1000;
}

.modal-styles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #fff; */
  padding: 50px;
  z-index: 1000;
}
/* Demo CSS */
body.active-modal {
  overflow-y: hidden;
}
