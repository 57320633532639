.btns {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px; 
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    color: inherit;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.btns:hover {
    background-color: white;
    color: black;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btns:focus {
    outline: none; 
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5); 
}

.btns-phone-active {
    background-color: rgb(205, 70, 70); 
    color: white;
}

.btns-phone-active:hover {
    background-color: rgb(214, 61, 61); 
    color: white;
}
