.nav-tabs .nav-link {
    background-color: #fff !important;
    color: rgb(121, 121, 121)!important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    font-size: 1.15rem;
    font-weight: 600;
    margin-left: 1.4rem;
  }
  
  .nav-tabs .nav-link.active {
    border-bottom: solid 2px #009ef7 !important;
    color: #009ef7 !important;
    padding-bottom: 1rem;
  }