
.card-info {
  font-size: 35px;
  margin-right: 10px;
}

.arrow-svg {
  width: 50px;
  height: 50px;
}

.up {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg)
    brightness(119%) contrast(119%);
}
.down {
  filter: invert(24%) sepia(89%) saturate(6610%) hue-rotate(357deg)
    brightness(100%) contrast(127%);
}
